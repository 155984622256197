import React from "react";
import Table from "rc-table";
import AdminOpenablePanel from "../../platform_reports/AdminOpenablePanel"
import ReportBubble from "../../platform_reports/ReportBubble"
import GeneralTable from "../../GeneralTable"
import TicketingCheckInModal from "./TicketingCheckInModal"
import { getMetadataValue } from "../../../utility/registration/RegFormUtility";
import Cookies from "universal-cookie";

class TicketingCheckInDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      participants: props.participants,
      ticketTypes: props.ticket_types
    }
    this.renderActions = this.renderActions.bind(this);
    this.updateParticipantCallback = this.updateParticipantCallback.bind(this);
  }

  componentDidMount() {
    // const interval = setInterval(() => {
    //   this.fetchData();
    // }, 60000);
    // this.setState({ interval: interval });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval)
  }

  fetchData() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch('/admin/get_tickets_and_participants', {
      method: 'GET',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        this.setState({
          participants: json.participants,
          ticketTypes: json.ticket_types
        })
      }
    });

  }

  getFilteredParticipants() {
    const { filterParticipants, participants } = this.state;
    return participants.filter(x=>!x.test_flag)
  }

  getFilteredRegistrants() {
    return this.getFilteredParticipants().filter(x=>x.status == "registered")
  }

  renderTicketStats(ticket_type, participants) {
    const totalTicketsAllocated = participants.map(x=> {
      let count = parseInt(getMetadataValue(ticket_type.slug, x["metadata"]));
      if (typeof count === "number" && !isNaN(count)) {
        return count;
      }
      return 0;
    }).reduce((a,b)=>(a||0)+(b||0), 0)

    const totalTicketsRecieved = participants.map(x=> {
      let attended = x.attended
      // let count = parseInt(getMetadataValue(`${ticket_type.slug}transferred`, x["metadata"]));
      if (attended) {
        let count = parseInt(getMetadataValue(ticket_type.slug, x["metadata"]));
        if (typeof count === "number" && !isNaN(count)) {
          return count;
        }
      }
      return 0;
    }).reduce((a,b)=>(a||0)+(b||0), 0)

    return (
      <div className="ticket-stats-block">
        <div className="ticket-stats-block-name">{ticket_type.name}</div>
        <div className="ticket-stats-block-count">
          {`${totalTicketsAllocated}`}
        </div>
        <div className="ticket-stats-block-count">
          {totalTicketsRecieved}
        </div>
      </div>
    );
  }

  renderTicketsStats() {
    const { ticketTypes } = this.state;
    let participants = this.getFilteredRegistrants();

    const dayTickets = ticketTypes.filter((ticket) => ticket.slug !== 'wimbledon1').map(ticket_type =>
      this.renderTicketStats(ticket_type, participants)
    );
    // console.log(dayTickets);
    const totalTicketsAllocated = ticketTypes.filter((ticket) => ticket.slug !== 'wimbledon1').map(ticket => 
      participants.map(x=> {
        let count = parseInt(getMetadataValue(ticket.slug, x["metadata"]));
        if (typeof count === "number" && !isNaN(count)) {
          return count;
        }
        return 0;
      }).reduce((a,b)=>(a||0)+(b||0), 0)
    ).reduce((a,b)=>(a||0)+(b||0), 0);

    const totalTicketsRecieved = ticketTypes.filter((ticket) => ticket.slug !== 'wimbledon1').map(ticket => 
      participants.map(x=> {
        let attended = x.attended
        // let count = parseInt(getMetadataValue(`${ticket.slug}transferred`, x["metadata"]));
        if (attended) {
          let count = parseInt(getMetadataValue(ticket.slug, x["metadata"]))
          if (typeof count === "number" && !isNaN(count)) {
            return count;
          }
        }
        return 0;
      }).reduce((a,b)=>(a||0)+(b||0), 0)
    ).reduce((a,b)=>(a||0)+(b||0), 0);

    const totalTicketsAvailable = ticketTypes.filter((ticket) => ticket.slug !== 'wimbledon1').map(ticket => 
      ticket.count
    ).reduce((a,b)=>(a||0)+(b||0), 0);

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="ticket-stats-wrapper">
            <div className="ticket-stats-block ticket-stats-block-desc">
              <div className="ticket-stats-block-name">&nbsp;</div>
              <div className="ticket-stats-block-count">Registered</div>
              <div className="ticket-stats-block-count">Checked in</div>
            </div>            
            {dayTickets}
            <div className="ticket-stats-block ticket-stats-block-all">
              <div className="ticket-stats-block-name">Event Total</div>
              <div className="ticket-stats-block-count">
                {`${totalTicketsAllocated}`}
              </div>
              <div className="ticket-stats-block-count">
                {totalTicketsRecieved}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGuests(row) {
    return [
      getMetadataValue('guestname', row["metadata"]),
      getMetadataValue('guestname2', row["metadata"]),
      getMetadataValue('guestname3', row["metadata"])
    ].filter(el =>
       el !== undefined && el !== ''
    ).join(', ');
  }

  getTableColumns() {
    const { ticketTypes } = this.state;
    let allColumns = [
      {title: 'Name', field: 'lower_full_name', render: (row) => row.full_name},
      {title: "Email", field: "email"},
      {title: "Type", field: "type"},
      {title: "Checked in", field: "attended"},
      // {title: "Checkedin Timestamp", field: "attended", render: (row) => this.renderTimeStamp(JSON.parse(getMetadataValue('checkinat', row["metadata"])))}

      // {title: "Guest Name", field: "guestname", render: (row) => this.renderGuests(row), customFilterAndSearch: (value, row) => {
      //   return this.renderGuests(row).toLowerCase().includes(value.toLowerCase());
      // }}
    ]

    allColumns = allColumns.concat([
      { title: 'Actions', field: 'actions', render: (row) => this.renderActions(row) },
    ])

    return allColumns;
  }

  renderTimeStamp(dateString) {
    if (dateString === '' || dateString === undefined) return '';
    let date = new Date(dateString);
    return date.toLocaleTimeString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  }

  sortByInt(a, b) {
    return parseInt(b, 10) - parseInt(a, 10);
  }

  sortByString(a, b) {
    return b - a;
  }

  updateParticipantCallback(new_participants) {
    this.setState({
      participants: new_participants
    })
  }

  renderActions(row) {
    const { ticketTypes } = this.state;
    return (
      <>
        <TicketingCheckInModal
          participant={row}
          ticketTypes={ticketTypes}
          // fields={fields}
          updateParticipantCallback={this.updateParticipantCallback}
        >
          <a href="#">Edit</a>
        </TicketingCheckInModal>
      </>
    )
  }

  getTablePeople() {
    const registrants = this.getFilteredRegistrants();
    // console.log(registrants);
    const mainRegistrants = registrants.map(x=>{
        x.lower_name_first = x.name_first.toLowerCase(),
        x.lower_name_last = x.name_last.toLowerCase(),
        x.full_name = `${x.name_first} ${x.name_last}`
        x.guest_name = `${x.registration_answers.guestfirstname||""} ${x.registration_answers.guestlastname||""}`
        x.lower_guest_name = `${x.registration_answers.guestfirstname||""} ${x.registration_answers.guestlastname||""}`.toLowerCase()
        x.lower_full_name = `${x.name_first} ${x.name_last}`.toLowerCase()
        x.has_guest = x.registration_answers.bringingguest
        return x
    })
    return mainRegistrants;
  }

  render() {
    const { surveys,emails, fields} = this.props;

    return (
      <div className="sg-admin-panel-container">
        <div className="sg-admin-openable-panel-header-custom-button" style={{float: "none", textAlign: "right", marginBottom: "20px"}}>
          <button className="sg-admin-openable-panel-header-custom-button-button" onClick={() => {location.href = '/admin'}}> Back to Admin Panel </button>
        </div>
        <AdminOpenablePanel
          header="Onsite Check-in Status"
          defaultOpen={true}
        >
          {/* {this.renderStats()} */}
          {this.renderTicketsStats()}
        </AdminOpenablePanel>
        <AdminOpenablePanel
          header="Participants"
          defaultOpen={true}
        >
          <GeneralTable
            columns={this.getTableColumns()}
            data={this.getTablePeople()}
          />
        </AdminOpenablePanel>
      </div>
    )
  }
}

export default TicketingCheckInDashboard;
